import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import JoditEditor from "jodit-react";
import config from "../coreFIles/config";
import Cookies from "js-cookie";
import { EditDomaincatdiscriptionAction, getDomainCatDiscriptionAction } from "../Action/action";
import { useParams } from "react-router-dom";

const loginData = Cookies.get("loginSuccessBondiAdmin")
  ? JSON.parse(Cookies.get("loginSuccessBondiAdmin"))
  : [];

const EditDomaincatdiscription = () => {
  const { id } = useParams();
  const {domain_id} = useParams();
  
  const editorHeight = "400px";

  const [domainCatDisc, setDomainCatDisc] = useState([]);
  const [spinLoader, setSpinLoader] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [catData, setCatData] = useState({
    meta_title: "",
    meta_desc: "",
    meta_keywords: "",
    domain_id: domain_id,
  });

  useEffect(() => {
    if (!loginData?.email) {
      window.location.href = `${config.baseUrl}login`;
    }
    getDomainCatDiscriptionApi();

    // Cleanup function to avoid memory leak on unmount
    return () => {
      setSpinLoader(false); // Reset loader on unmount
    };
  }, [id]);

  // Input handler for updating state
  const inputHandler = (e) => {
    const { name, value } = e.target;
    setCatData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handler for meta description changes (using JoditEditor)
  const descriptionHandler = (e) => {
    setCatData((prevState) => ({ ...prevState, meta_desc: e }));
  };

  const metaKeywordsHandler = (e) => {
    setCatData((prevState) => ({ ...prevState, meta_keywords: e }));
  };

  // Validation function for required fields (e.g., `meta_title`, etc.)
  const validate = () => {
    let errors = {};

    if (!catData.meta_title) {
      errors.meta_title = "Meta title is required.";
    }
    if (!catData.meta_desc) {
      errors.meta_desc = "Meta description is required.";
    }
    if (!catData.meta_keywords) {
      errors.meta_keywords = "Meta keywords are required.";
    }

    setValidationError(errors);
    return Object.keys(errors).length === 0;
  };

  // Fetch domain category description data
  const getDomainCatDiscriptionApi = async () => {
    try {
      const res = await getDomainCatDiscriptionAction({ domain_id: domain_id,cat_id:id });
      if (res.success) {
        setDomainCatDisc(res.data);
        console.log(res.data);
        setCatData((prevState) => ({
          ...prevState,
          meta_title: res.data[0]?.meta_title || "",
          meta_desc: res.data[0]?.meta_description || "",
          meta_keywords: res.data[0]?.meta_keyword || "",
        }));
      } else {
        setDomainCatDisc([]);
      }
    } catch (error) {
      toast.error("Failed to fetch domain category description.");
    }
  };

  // Submit edited data
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    setSpinLoader(true);

    try {
      const res = await EditDomaincatdiscriptionAction({
        catdata: catData,
        cat_id: id,
      });

      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
                    window.location.href = `${config.baseUrl}domain-discription/` +id;
                }, 2000);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      toast.error("Error while submitting data.");
    } finally {
      setSpinLoader(false);
    }
  };

  return (
    <>
      <div className="wrapper">
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-5 pb-2">Edit Description</h3>
                </div>
              </div>
              <hr />
            </div>

            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Edit Description</h4>
                      <a href={`${config.baseUrl}categories`} className="btn btn-sm btn-primary add_btn">
                        Back
                      </a>
                    </div>
                    <div className="row mt-20 mb-50 ml-15 mr-15">
                      <form onSubmit={handleSubmit}>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group row mb-1">
                                <label className="col-form-label col-md-12">Meta Title</label>
                                <div className="col-md-12">
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="meta_title"
                                    id="meta_title"
                                    onChange={inputHandler}
                                    placeholder="Enter meta title"
                                    value={catData.meta_title}
                                  />
                                  {validationError.meta_title && (
                                    <small className="text-danger">{validationError.meta_title}</small>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group row mb-1">
                                <label className="col-form-label col-md-12">Meta Description</label>
                                <div className="col-md-12">
                                  <JoditEditor
                                    onChange={descriptionHandler}
                                    value={catData.meta_desc}
                                    name="meta_desc"
                                    id="meta_desc"
                                    style={{ height: editorHeight }}
                                    className="form-control"
                                    placeholder="Enter meta description"
                                  />
                                  {validationError.meta_desc && (
                                    <small className="text-danger">{validationError.meta_desc}</small>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group row mb-1">
                                <label className="col-form-label col-md-12">Meta Keywords</label>
                                <div className="col-md-12">
                                  <JoditEditor
                                    onChange={metaKeywordsHandler}
                                    value={catData.meta_keywords}
                                    name="meta_keywords"
                                    id="meta_keywords"
                                    style={{ height: editorHeight }}
                                    className="form-control"
                                    placeholder="Enter meta keywords"
                                  />
                                  {validationError.meta_keywords && (
                                    <small className="text-danger">{validationError.meta_keywords}</small>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <br />
                              <div className="text-center pull-left">
                                {spinLoader ? (
                                  <button disabled className="btn btn-primary">
                                    Submitting <i className="fa fa-spinner fa-spin validat"></i>
                                  </button>
                                ) : (
                                  <button className="btn btn-primary">Submit</button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default EditDomaincatdiscription;
