import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import ReactDatatable from '@ashvin27/react-datatable';
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import Cookies from 'js-cookie';
import moment from 'moment';
import { updateUserAction, getUserDetailsAction, getUserPaymentHistoryAction, getCountriesListAction, getSearchSuburbAction } from "../Action/action";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import avt from '../assets/avata_profile.jpg'
import { Link, useParams } from 'react-router-dom';
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));
const Edituser = () => {
	const { id } = useParams();
	const [spinloader, setspinloader] = useState(0);
	const [suburbChanged, setsuburbChanged] = useState(0);
	const [showcompany, setshowcompany] = useState(1);
	const [currentDate, setcurrentDate] = useState(new Date());
	const [validatioError, setvalidatioError] = useState({});
	const [countryList, setcountryList] = useState([]);
	const [SuburbsearchResults, setSuburbsearchResults] = useState([]);
	const [suburb, setsuburb] = useState('');
	const [userdata, setuserdata] = useState({});
	const [userpaymentdata, seuserpaymentdata] = useState([]);

	useEffect(() => {
		if (!loginData?.email) {
			window.location.href = `${config.baseUrl}login`
		}
		getuserDetailsAPI();
		getUserPaymentHistoryAPI();
		getCountriesAPI();
	}, [])

	const inputHandler = async (e) => {
		const { name, value } = e.target;
		setuserdata((old) => {
			return { ...old, [name]: value };
		});
	};

	const getCountriesAPI = async () => {
		let res = await getCountriesListAction();
		if (res.success) {
			setcountryList(res.data);
		}
		else {
			setcountryList({});
		}
	}


	const getuserDetailsAPI = async () => {
		let res = await getUserDetailsAction({ id: id });
		if (res.success) {
			setuserdata(res.data);
			setsuburb(res.data.suburb);
			
		}
		else {
			setuserdata({});
		}
	}
	
	const getUserPaymentHistoryAPI = async () => {
		let res = await getUserPaymentHistoryAction({ id: id });
		if (res.success) {
			seuserpaymentdata(res.data);
		}
		else {
			seuserpaymentdata({});
		}
	}

	const inputmem_typeHandler = async (e) => {
		const value = e.target.value;

		if (value == 0) {
			setshowcompany(value);
			setuserdata((old) => {
				return { ...old, 'mem_type': value };
			});
		} else {
			setshowcompany(value);
			setuserdata((old) => {
				return { ...old, 'mem_type': value, 'mem_company': '' };
			});
		}
	};


	const SearchSuburb = async (str) => {
		try {
			setsuburb(str);
			let res = await getSearchSuburbAction({ str: str });
			setSuburbsearchResults(res.data);
		} catch (error) {
			console.error(error);
		}
	};

	const handleSelectSuburb = (row) => {
		setsuburbChanged(1);
		setsuburb(row);
		setuserdata((old) => {
			return { ...old, 'suburb': row };
		});
		setSuburbsearchResults({});
	};

	const columns = [
		{
			key: "Sno.",
			text: "#",
			cell: (row, index) => index + 1,
			width: '5%', 
		},
		{
			key: "recevied_on",
			text: "Recevied On",
			width: '15%',
			cell: (item) => {
				return (
					`${moment(item.recevied_on).format('DD/MM/YYYY')}`
				);
			}
		},
		{
			key: "from_date",
			text: "From Date",  
			width: '15%',
			cell: (item) => {
				return (
					`${moment(item.from_date).format('DD/MM/YYYY')}`
				);
			}
		},
		{
			key: "to_date",
			text: "To Date",
			width: '15%',
			cell: (item) => {
				return (
					`${moment(item.to_date).format('DD/MM/YYYY')}`
				);
			}
		},
		{
			key: "payment_amount",
			text: "Payment Amount",
			width: '20%',   
		},
		{
			key: "currency",
			text: "Currency",
			width: '15%',   
		},
		{
			key: "txn_id",
			text: "Transaction Id",
			width: '15%',
		},
	];

	const configForTable = {
		page_size: 10,
		length_menu: [10, 20, 50],
		show_filter: true,
		show_pagination: true,
		pagination: "advance",
		button: {
			excel: false,
			print: false,
		},
	};

	function validate() {
		let fnameError = "";
		let lnameError = "";
		let mem_typeError = "";
		let sexError = "";
		let countryError = "";
		let suburbError = "";
		let contact_noError = "";

		if (userdata.fname === "") {
			fnameError = "First name is required.";
			console.log(fnameError);
		}
		if (userdata.lname === "") {
			lnameError = "Last name is required.";
			console.log(lnameError);
		}

		if (userdata.country === "") {
			countryError = "Country is required.";
			console.log(countryError);
		}
		if (userdata.suburb === "") {
			suburbError = "Suburb is required.";
			console.log(suburbError);
		}
		if (userdata.mem_type === "") {
			mem_typeError = "Role is required.";
			console.log(mem_typeError);
		}
		if (userdata.sex === "") {
			sexError = "Gender is required.";
			console.log(sexError);
		}
		if (userdata.contact_no === "") {
			contact_noError = "Phone is required.";
			console.log(contact_noError);
		}
		if (fnameError || lnameError || sexError || countryError || suburbError || contact_noError) {

			setvalidatioError({
				fnameError, lnameError, sexError, countryError, suburbError, contact_noError
			});
			return false;
		} else {
			setvalidatioError({
				fnameError, lnameError, sexError, countryError, suburbError, contact_noError
			});
			return true;
		}
	}
	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput) {
			e.preventDefault();
		}
	};
	function validateEmail(email) {
		// Regular expression for email validation
		const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
		return regex.test(email);
	}
	const updateUser = async (e) => {
		e.preventDefault()
		const isValid = validate();
		console.log(isValid);
		if (!isValid) {
		}
		else {
			if (suburbChanged == 0) {
				userdata.suburb = `${userdata.suburb}-${userdata.state}-${userdata.zip}`
			}

			let res = await updateUserAction(userdata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}users`;
				}, 2000);
			}
			else {
				toast.error(res.msg);
			}
		}
	}

	return (
		<>
			<div className="wrapper">
				<Header />
				<Toaster />
				<Sidebar />
				<div className="content-wrapper">
					<div className="container-full">
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">Edit User</h3>
								</div>
							</div>
							<hr />
						</div>
						{/* Content Header (Page header) */}

						{/* Main content */}
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<h4 className="box-title">Edit User</h4>
											<a href={`${config.baseUrl}users`} className="btn btn-sm btn-primary add_btn">Back</a>
										</div>
										<div className="row mt-20 mb-50 ml-15 mr-15">
											<div className="col-md-12">
												<Tabs>
													<TabList>
														<Tab>Profile</Tab>
														<Tab>Payment History</Tab>       
													</TabList>
													<TabPanel>
														<form onSubmit={updateUser}>
															<div className="row">
																<div className="col-md-6">
																	<div className="form-group row mb-1">
																		<label className="col-form-label col-md-12">
																			Member Type <span className="req-star">*</span>
																		</label>
																		<div className="col-md-12">
																			<select name='mem_type' className="form-control" id='mem_typeError' onChange={inputmem_typeHandler} value={userdata?.mem_type}>
																				<option value="">Select Member Type</option>
																				<option value="1">Individual</option>
																				<option value="0">Company</option>
																			</select>
																		</div>
																		<span className="validationErr">
																			{validatioError.mem_typeError}
																		</span>
																	</div>
																</div>
																{showcompany == 0 ?
																	<>
																		<div className="col-md-6">
																			<div className="form-group row mb-1">
																				<label className="col-form-label col-md-12">
																					Company Name
																				</label>
																				<div className="col-md-12">
																					<input
																						className="form-control"
																						type="text"
																						name="mem_company"
																						id='mem_companyError'
																						onChange={inputHandler}
																						placeholder="Enter Company Name"
																						value={userdata?.mem_company}
																					/>
																				</div>
																			</div>
																		</div>
																	</>
																	: ''
																}

																<div className="col-md-6">
																	<div className="form-group row mb-1">
																		<label className="col-form-label col-md-12">
																			First Name <span className="req-star">*</span>
																		</label>
																		<div className="col-md-12">
																			<input
																				className="form-control"
																				type="text"
																				name="fname"
																				id='fnameError'
																				onChange={inputHandler}
																				placeholder="Enter First Name"
																				value={userdata?.fname}
																			/>
																		</div>
																		<span className="validationErr">
																			{validatioError.fnameError}
																		</span>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="form-group row mb-1">
																		<label className="col-form-label col-md-12">
																			Last Name <span className="req-star">*</span>
																		</label>
																		<div className="col-md-12">
																			<input
																				className="form-control"
																				type="text"
																				name="lname"
																				id='lnameError'
																				onChange={inputHandler}
																				placeholder="Enter Last Name"
																				value={userdata?.lname}
																			/>
																		</div>
																		<span className="validationErr">
																			{validatioError.lnameError}
																		</span>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="form-group row mb-1">
																		<label className="col-form-label col-md-12">
																			Gender <span className="req-star">*</span>
																		</label>
																		<div className="col-md-12">
																			<select name='sex' className="form-control" id='sexError' onChange={inputHandler} value={userdata?.sex}>
																				<option value="">-- Please select --</option>
																				<option value="0">Male</option>
																				<option value="1">Female</option>
																			</select>
																		</div>
																		<span className="validationErr">
																			{validatioError.lnameError}
																		</span>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="form-group row mb-1">
																		<label className="col-form-label col-md-12">
																			Email <span className="req-star">*</span>
																		</label>
																		<div className="col-md-12">
																			<input
																				className="form-control"
																				type="text"
																				name="email"
																				id='emailError'
																				onChange={inputHandler}
																				placeholder="Enter Email Address"
																				value={userdata?.email}
																				disabled={true}
																				readOnly={true}
																			/>
																		</div>
																		<span className="validationErr">
																			{validatioError.emailError}
																		</span>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="form-group row mb-1">
																		<label className="col-form-label col-md-12">
																			Where do you live? <span className="req-star">*</span>
																		</label>
																		<div className="col-md-12">
																			<select name='country' className="form-control" id='countryError' onChange={inputHandler} value={userdata?.country}>
																				<option value="">Select Country</option>
																				{countryList.length > 0 ?
																					countryList.map((item) => {
																						return (
																							<option value={item.country_code}>{item.country_name}</option>
																						)
																					})
																					: ''
																				}
																			</select>
																		</div>
																		<span className="validationErr">
																			{validatioError.roleError}
																		</span>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="form-group row mb-1">
																		<label className="col-form-label col-md-12">
																			Suburb<span className="req-star">*</span>
																		</label>
																		<div className="col-md-12">
																			<input type="text" name="suburb" className="form-control" id="suburb" onChange={(e) => SearchSuburb(e.target.value)} autocomplete="off" value={suburb}
																			/>

																		</div>
																		<span className="validationErr">
																			{validatioError.suburbError}
																		</span>
																		{SuburbsearchResults.length > 0 ? (
																			<ul className="suburbList">
																				{SuburbsearchResults.map((row) => (
																					<li key={row.id} onClick={() => handleSelectSuburb(row.rowlocation)}>
																						{row.rowlocation}
																					</li>
																				))}
																			</ul>
																		) : null}

																	</div>
																</div>
																<div className="col-md-6">
																	<div className="form-group row mb-1">
																		<label className="col-form-label col-md-12">
																			Phone Number <span className="req-star">*</span>
																		</label>
																		<div className="col-md-12">
																			<input
																				className="form-control"
																				type="text"
																				name="contact_no"
																				id='contact_noError'
																				onChange={inputHandler}
																				placeholder="Enter Phone Number"
																				onKeyPress={handleKeyPress}
																				value={userdata?.contact_no}
																			/>
																		</div>
																		<span className="validationErr">
																			{validatioError.contact_noError}
																		</span>
																	</div>
																</div>

																<div className="col-md-6">
																	<div className="form-group row mb-1">
																		<label className="col-form-label col-md-12"> Have a website or blog?  </label>
																		<div className="col-md-12">
																			<input
																				className="form-control"
																				type="text"
																				name="website"
																				id='website'
																				onChange={inputHandler}
																				placeholder="Enter website address"
																				value={userdata?.website}
																			/>
																		</div>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="form-group row mb-1">
																		<label className="col-form-label col-md-12"> Membership Type  </label>
																		<div className="col-md-12">
																			<select name='membership_type' className="form-control" id='membership_typeError' onChange={inputHandler} value={userdata?.membership_type}>
																				<option value="">Select membership type</option>
																				<option value="1">Basic</option>
																				<option value="2">VIP</option>
																			</select>
																		</div>
																	</div>
																</div>
																<div className="col-md-12">
																	<br />
																	<div className="text-center pull-left">
																		{spinloader == '0' ?
																			<button className='btn btn-primary' >Submit</button>
																			:
																			<button disabled className='btn btn-primary' >Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
																		}
																	</div>
																</div>
															</div>
														</form>
													</TabPanel>
													<TabPanel>
														<div className="box-body">
															<ReactDatatable
																config={configForTable}
																records={userpaymentdata}
																columns={columns}
															/>
														</div>
													</TabPanel>
												</Tabs>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						{/* /.content */}
					</div>
				</div>

				<Footer />
			</div>
		</>
	);
};
export default Edituser;
