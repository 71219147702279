import React, { useEffect, useState } from 'react';
import Header from '../directives/header';
import Sidebar from '../directives/sidebar';
import Footer from '../directives/footer';
import toast, { Toaster } from 'react-hot-toast';
import config from '../coreFIles/config';
import { useParams } from 'react-router-dom';
import { gettingDomainAction, updateDomainAction } from '../Action/action'

const Editdomain = () => {

    const { id } = useParams()
    const [form, setform] = useState({})
    const [validationError, setValidationError] = useState({});
    console.log(form.domain_img)
    // reload page get doamin
    useEffect(() => {
        getDomainApi()
    }, [])
    // getting value of input feilds
    const inputHandler = async (e) => {
        const { name, value } = e.target;
        setform((old) => {
            return { ...old, [name]: value };
        });
    };

    // getting domain with id
    const getDomainApi = async () => {
        const res = await gettingDomainAction({ id: id });
        if (res.success) {
            setform(res.data)
            console.log(res.data)
        } else {
            toast.error(res.msg);
        }
    };
    // getting value input type files
    const PicChange = async (e) => {
        e.preventDefault();
        const name = e.target.name;
        const file = e.target.files[0];
        if (file) {
            
            setform((old) => ({
                ...old,
                [name]: file,
            }));
        } else {
            setform((old) => ({
                ...old,
                [name]: old[name],
            }));
        }
    };
    // update the domain 
    const updateDomainApi = async (e) => {
        e.preventDefault();
        console.log(form)
        let res = await updateDomainAction({
            ...form,
            id: id
        })
        if (res.success) {
            toast.success(res.msg)
            getDomainApi()
        }
        else {
            toast.error(res.msg)
        }
    }


    return (
        <div className="wrapper">
            <Header />
            <Toaster />
            <Sidebar />
            <div className="content-wrapper">


                <div className="container-full">
                    <div className="content-header">
                        <h3 className="page-title mb-5 pb-2">Edit domain</h3>
                        <hr />
                    </div>

                    <section className="content">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="box">
                                    <div className="box-header with-border">
                                        <h4 className="box-title">Edit domain</h4>
                                        <a href={`${config.baseUrl}domains`} className="btn btn-sm btn-primary add_btn">Back</a>
                                    </div>

                                    <div className="row mt-20 mb-50 ml-15 mr-15">
                                        <form >
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="col-form-label">
                                                                Domain url <span className="req-star"></span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="url"
                                                                onChange={inputHandler}
                                                                placeholder="Enter Domain URL"
                                                                value={form.url}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="col-form-label">
                                                                Domain title <span className="req-star"></span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="title"
                                                                onChange={inputHandler}
                                                                placeholder="Enter Title"
                                                                value={form.title}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label className="col-form-label">
                                                                Domain web master <span className="req-star"></span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="domain_webmaster"
                                                                onChange={inputHandler}
                                                                placeholder="Enter Webmaster"
                                                                value={form.domain_webmaster}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group ">

                                                            <label className="col-form-label">
                                                                Zip range
                                                            </label>
                                                            <textarea
                                                                className="form-control"
                                                                name="zip_range"
                                                                onChange={inputHandler}
                                                                placeholder="Enter Zip Range"
                                                                value={form?.zip_range}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group ">
                                                            <label className="col-form-label">
                                                                Show ads by range
                                                            </label>
                                                            <div className='d-flex justify-center grid gap-2 grid-cols-2' >

                                                                <input
                                                                    style={{
                                                                        position: "sticky",
                                                                        opacity: "1"
                                                                    }}
                                                                    type="radio"
                                                                    name="showAds"
                                                                    value="0"
                                                                    checked={form?.showlocalnews === 0}
                                                                    onChange={inputHandler}
                                                                /> Yes

                                                                <input
                                                                    style={{
                                                                        position: "sticky",
                                                                        opacity: "1"
                                                                    }}
                                                                    type="radio"
                                                                    name="showAds"
                                                                    value="1"
                                                                    checked={form?.showlocalnews === 1}
                                                                    onChange={inputHandler}
                                                                /> No

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="row">
                                                    <div className="form-group col-lg-6">
                                                        <label className="col-form-label">
                                                            Domain image: <span className="req-star">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="domainImage"

                                                            onChange={PicChange}

                                                        />
                                                        <img
                                                           src={`${config.domainImg + form?.domain_img}`}
                                                            className="img-preview"
                                                            onError={() => console.log('Image failed to load')}
                                                        />

                                                    </div>

                                                    <div className="form-group col-lg-6">
                                                        <label className="col-form-label">
                                                            Online image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="onlineImage"
                                                            onChange={PicChange}
                                                        />

                                                        <img
                                                             src={`${config.domainImg + form?.dom_Onlineimg}`}

                                                            className="img-preview"
                                                        />

                                                    </div>
                                                    <div className="form-group col-lg-6">
                                                        <label className="col-form-label">
                                                            Left sidebar free ads image:
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="freeAdsImage"
                                                            onChange={PicChange}
                                                        />


                                                        <img
                                                             src={`${config.domainImg + form?.dom_sidefreead}`}

                                                            className="img-preview"
                                                        />

                                                    </div>
                                                    <div className="form-group col-lg-6">
                                                        <label className="col-form-label">
                                                            Left sidebar manage ad image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="manageAdImage"
                                                            onChange={PicChange}
                                                        />

                                                        <img
                                                            src={`${config.domainImg + form?.manage_ad}`}
                                                            className="img-preview"
                                                        />

                                                    </div>
                                                    <div className="form-group col-lg-6">
                                                        <label className="col-form-label">
                                                            Left sidebar login image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="loginImage"
                                                            onChange={PicChange}
                                                        />

                                                        <img
                                                             src={`${config.domainImg + form?.dom_sidelogin}`}
                                                            className="img-preview"
                                                        />

                                                    </div>
                                                    <div className="form-group col-lg-6">
                                                        <label className="col-form-label">
                                                            Left sidebar searched image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="searchedImage"
                                                            onChange={PicChange}
                                                        />

                                                        <img
                                                             src={`${config.domainImg + form?.searchedlistimg}`}
                                                            className="img-preview"
                                                        />

                                                    </div>
                                                    <div className="form-group col-lg-6">
                                                        <label className="col-form-label">
                                                            Left sidebar fev-list image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="fevListImage"
                                                            onChange={PicChange}
                                                        />

                                                        <img
                                                             src={`${config.domainImg + form?.fevadsimg}`}

                                                            className="img-preview"
                                                        />

                                                    </div>
                                                    <div className="form-group col-lg-6">
                                                        <label className="col-form-label">
                                                            Map image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="mapImage"
                                                            onChange={PicChange}
                                                        />

                                                        <img
                                                            src={`${config.domainImg + form?.map}`}

                                                            className="img-preview"
                                                        />
                                                    </div>
                                                    <div className="form-group col-lg-6">
                                                        <label className="col-form-label">
                                                            Domore image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="domoreImage"
                                                            onChange={PicChange}
                                                        />

                                                        <img
                                                            src={`${config.domainImg + form?.domore}`}

                                                            className="img-preview"
                                                        />

                                                    </div>
                                                    <div className="form-group col-lg-6">
                                                        <label className="col-form-label">
                                                            Fb login image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="fbLoginImage"
                                                            onChange={PicChange}
                                                        />

                                                        <img
                                                            src={`${config.domainImg + form?.fblogin}`}

                                                            className="img-preview"
                                                        />


                                                    </div>
                                                    <div className="form-group col-lg-6">
                                                        <label className="col-form-label">
                                                            Fb logout image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="fbLogoutImage"
                                                            onChange={PicChange}
                                                        />

                                                        <img
                                                              src={`${config.domainImg + form?.fblogout}`}

                                                            className="img-preview"
                                                        />

                                                    </div>
                                                </div>

                                                <h3>Classified SEO</h3>
                                                <div className="row">
                                                    {/* Meta Title */}
                                                    <div className="form-group col-lg-4">
                                                        <label className="col-form-label">
                                                            Meta title
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            name="domain_meta_title"
                                                            onChange={inputHandler}
                                                            placeholder="Enter Meta Title"
                                                            value={form?.domain_meta_title}
                                                        />
                                                        <span className="validationErr">{validationError.metaTitleError}</span>
                                                    </div>

                                                    {/* Meta Description */}
                                                    <div className="form-group col-lg-4">
                                                        <label className="col-form-label">
                                                            Meta description
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            name="domain_meta_desc"
                                                            onChange={inputHandler}
                                                            placeholder="Enter Meta Description"
                                                            value={form?.domain_meta_desc}
                                                        />
                                                        <span className="validationErr">{validationError.metaDescriptionError}</span>
                                                    </div>

                                                    {/* Meta Keywords */}
                                                    <div className="form-group col-lg-4">
                                                        <label className="col-form-label">
                                                            Meta keywords
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            name="domain_meta_keywords"
                                                            onChange={inputHandler}
                                                            placeholder="Enter Meta Keywords"
                                                            value={form?.domain_meta_keywords}
                                                        />
                                                        <span className="validationErr">{validationError.metaKeywordsError}</span>
                                                    </div>



                                                    {/* Facebook Share Title */}
                                                    <div className="form-group col-lg-4">
                                                        <label className="col-form-label">
                                                            Facebook share title <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="fbsharetitle"
                                                            onChange={inputHandler}
                                                            placeholder="Enter Facebook Share Title"
                                                            value={form?.fbsharetitle}
                                                        />
                                                        <span className="validationErr">{validationError.fbShareTitleError}</span>
                                                    </div>

                                                    {/* Facebook Share Description */}
                                                    <div className="form-group col-lg-4">
                                                        <label className="col-form-label">
                                                            Facebook share description <span className="req-star"></span>
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            name="fbsharedescription"
                                                            onChange={inputHandler}
                                                            placeholder="Enter Facebook Share Description"
                                                            value={form?.fbsharedescription}
                                                        />
                                                        <span className="validationErr">{validationError.fbShareDescriptionError}</span>
                                                    </div>
                                                    <div className="form-group d-flex grid gap-2 grid-cols-2">
                                                        <button type="submit" className="btn btn-primary" onClick={updateDomainApi} >Submit</button>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </section>
                </div>
            </div>


            <Footer />
        </div>
    )
}

export default Editdomain;