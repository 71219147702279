import {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
    postRequestFormData,
    postRequestadFormData,
} from "../coreFIles/helper";


export const LoginAction = (data) => {
    return postRequest("adminLogin", data).then((res) => {
        return res.data;
    });
};

export const changePasswordAction = (data) => {
    return postRequest("changePassword", data).then((res) => {
        return res.data;
    });
};

export const getAdminProfiles = (data) => {
    return postRequest('getAdminProfiles', data).then(res => { return res.data })
}

export const getDashboardStatisticsAction = (data) => {
    return postRequest("getDashboardStatistics", data).then((res) => {
        return res.data;
    });
};

export const getCountriesListAction = (data) => {
    return postRequest('getCountriesList', data).then(res => { return res.data })
}

export const getsettingsListAction = (data) => {
    return getRequest('getsettingsList', data).then(res => { return res.data })
}

export const updatesettingsListsAction = (data) => {
    return postRequest("updatesettingsLists", data).then((res) => {
        return res.data;
    });
};

export const getPlansListAction = (data) => {
    return postRequest("getPlansList", data).then((res) => {
        return res.data;
    });
};

export const createPlanAction = (data) => {
    return postRequest("createPlan", data).then((res) => {
        return res.data;
    });
};

export const getPlanDetailsAction = (data) => {
    return postRequest("getPlanDetail", data).then((res) => {
        return res.data;
    });
};

export const UpdatePlanAction = (data) => {
    return postRequest("UpdatePlan", data).then((res) => {
        return res.data;
    });
};

export const PlanDeleteAction = (data) => {
    return postRequest("PlanDelete", data).then((res) => {
        return res.data;
    });
};

export const PlanBlockUnBlockAction = (data) => {
    return postRequest("PlanBlockUnBlock", data).then((res) => {
        return res.data;
    });
};

export const getCategoriesListAction = (data) => {
    return postRequest("getCategoriesList", data).then((res) => {
        return res.data;
    });
};

export const getChildCategoriesListAction = (data) => {
    return getRequest("getChildCategoriesList", data).then((res) => {
        return res.data;
    });
};

export const CategoryBlockUnBlockAction = (data) => {
    return postRequest("CategoryBlockUnBlock", data).then((res) => {
        return res.data;
    });
};

export const CategoryDeleteAction = (data) => {
    return postRequest("categoryDelete", data).then((res) => {
        return res.data;
    });
};

export const getCategoryDetailAction = (data) => {
    return postRequest("getCategoryDetail", data).then((res) => {
        return res.data;
    });
};

export const updateCategoryAction = (data) => {
    return postRequest("updateCategory", data).then((res) => {
        return res.data;
    });
};

export const createCategoryAction = (data) => {
    return postRequest("createCategory", data).then((res) => {
        return res.data;
    });
};

export const getSubCategoriesListAction = (data) => {
    return postRequest("getSubCategoriesList", data).then((res) => {
        return res.data;
    });
};


export const getUserslistAction = (data) => {
    return postRequest("getUsersList", data).then((res) => {
        return res.data;
    });
};

export const getSearchSuburbAction = (data) => {
    return postRequest("getSearchSuburb", data).then((res) => {
        return res.data;
    });
};

export const createUserAction = (data) => {
    return postRequest("createUser", data).then((res) => {
        return res.data;
    });
};

export const getUserDetailsAction = (data) => {
    return postRequest("getUserDetails", data).then((res) => {
        return res.data;
    });
};

export const getUserPaymentHistoryAction = (data) => {
    return postRequest("getUserPaymentHistory", data).then((res) => {
        return res.data;
    });
};

export const updateUserAction = (data) => {
    return postRequest("updateUser", data).then((res) => {
        return res.data;
    });
};

export const getTotalUsersListAction = (data) => {
    return postRequest("getTotalUsersList", data).then((res) => {
        return res.data;
    });
};

export const UserBlockUnBlockAction = (data) => {
    return postRequest("UserBlockUnBlock", data).then((res) => {
        return res.data;
    });
};

export const UserDeleteAction = (data) => {
    return postRequest("UserDelete", data).then((res) => {
        return res.data;
    });
};

export const createBannerAction = (data) => {
    return postRequestFormData("createBanner", data).then((res) => {
        return res.data;
    });
};

export const getBannerslistAction = (data) => {
    return postRequest("getBannerslist", data).then((res) => {
        return res.data;
    });
};

export const getBannerDetailsAction = (data) => {
    return postRequest("getBannerDetails", data).then((res) => {
        return res.data;
    });
};

export const updateBannerAction = (data) => {
    return postRequestFormData("updateBanner", data).then((res) => {
        return res.data;
    });
};

export const BannerBlockUnBlockAction = (data) => {
    return postRequest("BannerBlockUnBlock", data).then((res) => {
        return res.data;
    });
};

export const BannerDeleteAction = (data) => {
    return postRequest("BannerDelete", data).then((res) => {
        return res.data;
    });
};

export const getHrzntlBannerslistAction = (data) => {
    return postRequest("getHrzntlBannerslist", data).then((res) => {
        return res.data;
    });
};

export const createHorizontalBannerAction = (data) => {
    return postRequestFormData("createHorizontalBanner", data).then((res) => {
        return res.data;
    });
};

export const getHorizontalBannerDetailAction = (data) => {
    return postRequest("getHorizontalBannerDetail", data).then((res) => {
        return res.data;
    });
};

export const updateHorizontalBannerAction = (data) => {
    return postRequestFormData("updateHorizontalBanner", data).then((res) => {
        return res.data;
    });
};

export const HrzntlBannerBlockUnBlockAction = (data) => {
    return postRequest("hrzntlBannerBlockUnBlock", data).then((res) => {
        return res.data;
    });
};

export const HrzntlBannerDeleteAction = (data) => {
    return postRequest("hrzntlBannerDelete", data).then((res) => {
        return res.data;
    });
};

export const getSpamlistAction = (data) => {
    return postRequest("getSpamList", data).then((res) => {
        return res.data;
    });
};

export const getTotalSpamListAction = (data) => {
    return postRequest("getTotalSpamList", data).then((res) => {
        return res.data;
    });
};

export const spamBlockUnBlockAction = (data) => {
    return postRequest("spamBlockUnBlock", data).then((res) => {
        return res.data;
    });
};

export const spamDeleteAction = (data) => {
    return postRequest("spamDelete", data).then((res) => {
        return res.data;
    });
};

export const createSpamTextAction = (data) => {
    return postRequest("createSpamText", data).then((res) => {
        return res.data;
    });
};

export const getSpamtextDetailAction = (data) => {
    return postRequest("getSpamtextDetail", data).then((res) => {
        return res.data;
    });
};

export const updateSpamTextAction = (data) => {
    return postRequest("updateSpamText", data).then((res) => {
        return res.data;
    });
};

export const getAdlistAction = (data) => {
    return postRequest("getAdlist", data).then((res) => {
        return res.data;
    });
};

export const getTotalAdListAction = (data) => {
    return postRequest("getTotalAdList", data).then((res) => {
        return res.data;
    });
};

export const getParentCatsAction = (data) => {
    return postRequest("getParentCats", data).then((res) => {
        return res.data;
    });
};

export const getChildCatsAction = (data) => {
    return postRequest("getChildCats", data).then((res) => {
        return res.data;
    });
};

export const getPremiumPlansAction = (data) => {
    return postRequest("getPremiumPlans", data).then((res) => {
        return res.data;
    });
};

export const getFeaturedPlansAction = (data) => {
    return postRequest("getFeaturedPlans", data).then((res) => {
        return res.data;
    });
};

export const getAdImagesAction = (data) => {
    return postRequest("getAdImages", data).then((res) => {
        return res.data;
    });
};

export const AdImageDeleteAction = (data) => {
    return postRequest("AdImageDelete", data).then((res) => {
        return res.data;
    });
};

export const createAdAction = (data) => {
    return postRequestadFormData("createAd", data).then((res) => {
        return res.data;
    });
};

export const addAdImgAction = (data) => {
    return postRequestadFormData("addAdImg", data).then((res) => {
        return res.data;
    });
};

export const getAdDetailAction = (data) => {
    return postRequest("getAdDetail", data).then((res) => {
        return res.data;
    });
};

export const getAdPaymentHistoryAction = (data) => {
    return postRequest("getAdPaymentHistory", data).then((res) => {
        return res.data;
    });
};

export const addAdPlanAction = (data) => {
    return postRequest("addAdPlan", data).then((res) => {
        return res.data;
    });
};

export const updateAdAction = (data) => {
    return postRequestFormData("updateAd", data).then((res) => {
        return res.data;
    });
};

export const adBlockUnBlockAction = (data) => {
    return postRequest("adBlockUnBlock", data).then((res) => {
        return res.data;
    });
};

export const adDeleteAction = (data) => {
    return postRequest("adDelete", data).then((res) => {
        return res.data;
    });
};

export const getContentlistAction = (data) => {
    return postRequest("getContentlist", data).then((res) => {
        return res.data;
    });
};

export const ContentDeleteAction = (data) => {
    return postRequest("ContentDelete", data).then((res) => {
        return res.data;
    });
};

export const ContentBlockUnBlockAction = (data) => {
    return postRequest("ContentBlockUnBlock", data).then((res) => {
        return res.data;
    });
};

export const createContentAction = (data) => {
    return postRequest("createContent", data).then((res) => {
        return res.data;
    });
};

export const getMasterContentAction = (data) => {
    return postRequest("getMasterContent", data).then((res) => {
        return res.data;
    });
};

export const getContentDetailAction = (data) => {
    return postRequest("getContentDetail", data).then((res) => {
        return res.data;
    });
};

export const updateContentAction = (data) => {
    return postRequest("updateContent", data).then((res) => {
        return res.data;
    });
};

export const getEmaillistAction = (data) => {
    return postRequest("getEmaillist", data).then((res) => {
        return res.data;
    });
};

export const EmailDeleteAction = (data) => {
    return postRequest("emailDelete", data).then((res) => {
        return res.data;
    });
};

export const getTemplateTypeAction = (data) => {
    return postRequest("getTemplateType", data).then((res) => {
        return res.data;
    });
};

export const getEmailtemplateAction = (data) => {
    return postRequest("getEmailtemplate", data).then((res) => {
        return res.data;
    });
};

export const createEmailtemplateAction = (data) => {
    return postRequest("createEmailtemplate", data).then((res) => {
        return res.data;
    });
};

export const updateEmailtemplateAction = (data) => {
    return postRequest("updateEmailtemplate", data).then((res) => {
        return res.data;
    });
};

export const getSliderslistAction = (data) => {
    return postRequest("getSliderslist", data).then((res) => {
        return res.data;
    });
};

export const createSliderAction = (data) => {
    return postRequestFormData("createSlider", data).then((res) => {
        return res.data;
    });
};

export const getSliderDetailsAction = (data) => {
    return postRequest("getSliderDetails", data).then((res) => {
        return res.data;
    });
};

export const updateSliderAction = (data) => {
    return postRequestFormData("updateSlider", data).then((res) => {
        return res.data;
    });
};

export const SliderBlockUnBlockAction = (data) => {
    return postRequest("SliderBlockUnBlock", data).then((res) => {
        return res.data;
    });
};

export const SliderDeleteAction = (data) => {
    return postRequest("SliderDelete", data).then((res) => {
        return res.data;
    });
};

export const getvipMemberslistAction = (data) => {
    return postRequest("getvipMemberslist", data).then((res) => {
        return res.data;
    });
};

export const getAdPaymentlistAction = (data) => {
    return postRequest("getAdPaymentlist", data).then((res) => {
        return res.data;
    });
};
// ------------------------getting domains List Action----------------->
export const getdomainListAction = (data)=>{
    return postRequest("getdomainList",data).then((res)=>{
        return res.data
    })
}
// ----------------------------deteteDomainAction---------------->
export const deteteDomainAction =(data)=>{
    return postRequest("deteteDomain",data).then((res)=>{
        return res.data
    })
}
export const saveaddDomainAction = (data)=>{
    return postRequestFormData("saveaddDomain",data).then((res)=>{
        return res.data
    })
}
// -----------------------------getting Domain Action with id -------------//
export const gettingDomainAction =(data)=>{
    return postRequestFormData("gettingDomain",data).then((res)=>{
       return res.data
    })
}
// -------------------------updateDomainAction---------------------------//
export const updateDomainAction =(data)=>{
    return postRequestFormData("updateDomain",data).then((res)=>{
       return res.data
    })
}
// -------------------------getDomain Cat Discription Action---------->
export const getDomainCatDiscriptionAction =(data)=>{
    return postRequest("getDomainCatDiscription",data).then((res)=>{
       return res.data
    })
}

// ------------------------------DeleteDomaincatdiscription-----------//
export const DeleteDomaincatdiscriptionAction =(data)=>{
    return postRequest("DeleteDomaincatdiscription",data).then((res)=>{
       return res.data
    })
}
// ------------------------------AddDomaincatdiscriptionAction-----------//
export const AddDomaincatdiscriptionAction =(data)=>{
    return postRequest("AddDomaincatdiscription",data).then((res)=>{
       return res.data
    })
}
// ------------------------------EditDomaincatdiscriptionAction-----------//
export const EditDomaincatdiscriptionAction =(data)=>{
    return postRequest("EditDomaincatdiscription",data).then((res)=>{
       return res.data
    })
}
