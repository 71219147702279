import React, { useEffect, useState } from 'react';
import Header from '../directives/header';
import Sidebar from '../directives/sidebar';
import Footer from '../directives/footer';
import toast, { Toaster } from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import config from '../coreFIles/config';
import { getDomainCatDiscriptionAction, DeleteDomaincatdiscriptionAction } from '../Action/action';

const Domaindiscription = () => {
    const [domainCatDisc, setdomainCatDisc] = useState([]);
    const {id} = useParams()
    
    useEffect(() => {
        getDomainCatDiscriptionApi();
    }, []);

    const getDomainCatDiscriptionApi = async () => {
        try {
            const res = await getDomainCatDiscriptionAction({cat_id:id});
            if (res.success) {
               setdomainCatDisc(res.data);
            } else {
                setdomainCatDisc([]);
            }
        } catch (error) {
            toast.error('Failed to fetch domain list.');
        }
    };

    const deteteDomainCatDiscriptionApi = async (detail) => {
		console.log(detail?.domain_id );
        Swal.fire({
            title: 'Are you sure? ',
            text: "You want to Delete this Domain Discription!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await DeleteDomaincatdiscriptionAction({Domain_id:detail?.domain_id})
                if (res.success) {
                    toast.success(res.msg);
                    getDomainCatDiscriptionApi();
                } else {
                    toast.error(res.msg);
                }
            }
        });
    }


    return (

        <div className="wrapper">
            <Toaster />
            <Header />
            <Sidebar />
            <div className="content-wrapper">
                <div className="container-full">
                    <div className="content-header">
                        <div className="d-flex align-items-center">
                            <div className="me-auto">
                                <h3 className="page-title mb-5 pb-2"> Domain Description List</h3>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <section className="content">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="box">
                                    <div className="box-header with-border">
                                        <div className="row mb-15">
                                            <h4 className="box-title col-md-2">List ({domainCatDisc.length})</h4>
                                            <div className="col-md-10 text-right">
                                                <Link to={`${config.baseUrl}add-domain-cat-discription/${id}`}>
                                                    <button className="btn btn-sm btn-primary add_btn">
                                                        <i className='fa fa-plus'></i> Add Domain Discription
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-body table-container ">

                                        <table className='table table-bordered table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Category name</th>
                                                    <th>Domain </th>
                                                    <th>Discription</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {domainCatDisc.map((detail, index) => (
                                                    <tr key={detail.id} >
                                                        <td>{index + 1}</td>
                                                        <td>{detail.title}</td>
                                                        <td>{detail.domain_title}</td>
                                                        <td >
                                                        {detail.meta_description.length > 40 ? `${detail.meta_description.substring(3,58)}...` : detail.meta_description}
                                                         
                                                        </td>
                                                        <td>
                                                            <Link to={`${config.baseUrl}edit-domain-cat-discription/${id}/${detail.domain_id}`}>
                                                                <button className='btn btn-sm btn-primary'>
                                                                    <i className='fa fa-pencil'></i> Edit
                                                                </button>
                                                                &nbsp;&nbsp;&nbsp;
                                                            </Link>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => deteteDomainCatDiscriptionApi(detail)}>
                                                                <i className='fa fa-trash'></i> Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
            <Footer />
        </div >


    );
};

export default Domaindiscription;
