import React, { useState } from 'react';
import Header from '../directives/header';
import Sidebar from '../directives/sidebar';
import Footer from '../directives/footer';
import toast, { Toaster } from 'react-hot-toast';
import config from '../coreFIles/config';
import { saveaddDomainAction } from '../Action/action';

const AddDomain = () => {
    const [form, setForm] = useState({
        domainUrl: '',
        title: '',
        webmaster: '',
        zipRange: '',
        showAds: '',
        domainImage: '',
        onlineImage: '',
        mapImage: '',
        freeAdsImage: '',
        manageAdImage: '',
        loginImage: '',
        domoreImage: '',
        fbLoginImage: '',
        fbLogoutImage: '',
        searchedImage: '',
        fevListImage: '',
        metaTitle: '',
        metaDescription: '',
        metaKeywords: '',
        fbShareTitle: '',
        fbShareDescription: ''
    });
  

    const [validationError, setValidationError] = useState({});

    const inputHandler = (e) => {
        const { name, value } = e.target;
        setForm((old) => {
            return { ...old, [name]: value };
        });     
    };
    const PicChange = async (e) => {
        e.preventDefault();
        let image_as_file = e.target.files[0];
        let name = e.target.name;
        setForm((old) => ({
            ...old,
            [name]: image_as_file, 
        }));
    
        console.log(image_as_file, name);
    };
    
    function validate() {
        let domainUrlError = '';
        let titleError = "";
        let webmasterError = "";
        let zipRangeError = ""
        let domainImageError = "";
        let onlineImageError = '';
        let freeAdsImageError = "";
        let manageAdImageError = "";
        let loginImageError = ""
        let searchedImageError = "";
        let fevListImageError = '';
        let mapImageError = "";
        let domoreImageError = "";
        let fbLoginImageError = ""
        let fbLogoutImageError = "";
        let metaTitleError = '';
        let metaDescriptionError = "";
        let metaKeywordsError = "";
        let fbShareTitleError = ""
        let fbShareDescriptionError = "";
        let selecteError = ""

        if (form.domainUrl === '') {
            domainUrlError = 'Enter domain url'
        }
        if (form.title === '') {
            titleError = 'Enter title'
        }
        if (form.webmaster === '') {
            webmasterError = 'Enter domain web master'
        }
        if (form.zipRange === '') {
            zipRangeError = "Enter zip range"
        }
        if (form.domainImage === '') {
            domainImageError = 'Add  image'
        }
        if (form.onlineImage === '') {
            onlineImageError = 'Add online image'
        }
        if (form.freeAdsImage === '') {
            freeAdsImageError = 'Add  image'
        }
        if (form.manageAdImage === '') {
            manageAdImageError = 'Add  image'
        }
        if (form.mapImage === '') {
            mapImageError = "Add image"
        }
        if (form.searchedImage === '') {
            searchedImageError = "Add  image"
        }
        if (form.fbLoginImage === '') {
            fbLoginImageError = "Add  image"
        }
        if (form.fbLogoutImage === '') {
            fbLogoutImageError = 'Add image'
        }
        if (form.fbShareTitle === '') {
            fbShareTitleError = 'Add fb share title'
        }
        if (form.fbShareDescription === '') {
            fbShareDescriptionError = 'Add fb description'
        }
        if (form.metaDescription === '') {
            metaDescriptionError = "Enter meta description"
        }
        if (form.metaKeywords === '') {
            metaKeywordsError = 'Enter meta key word'
        }
        if (form.metaTitle === '') {
            metaTitleError = 'Enter meta title'
        }
        if (form.loginImage === '') {
            loginImageError = 'Add image '
        }
        if (form.fevListImage === '') {
            fevListImageError = 'Add image'
        }
        if (form.showAds === '') {
            selecteError = 'please select one'
        }
        if (form.domoreImage === '') {
            domoreImageError = "Add image"
        }
        if (domainUrlError || titleError || webmasterError || zipRangeError || domainImageError || onlineImageError || freeAdsImageError || manageAdImageError || mapImageError || searchedImageError || fbLoginImageError || fbLogoutImageError || fbShareTitleError || fbShareDescriptionError || metaDescriptionError || metaKeywordsError || metaTitleError || loginImageError || fevListImageError || domoreImageError || selecteError) {
            setValidationError({
                domainUrlError, titleError, webmasterError, zipRangeError, domainImageError, onlineImageError, freeAdsImageError, manageAdImageError, mapImageError, searchedImageError, fbLoginImageError, fbLogoutImageError, fbShareTitleError, fbShareDescriptionError, metaDescriptionError, metaKeywordsError, metaTitleError, loginImageError, fevListImageError,  domoreImageError, selecteError
            })
            return false
        }
        else {
            setValidationError({
                domainUrlError, titleError, webmasterError, zipRangeError, domainImageError, onlineImageError, freeAdsImageError, manageAdImageError, mapImageError, searchedImageError, fbLoginImageError, fbLogoutImageError, fbShareTitleError, fbShareDescriptionError, metaDescriptionError, metaKeywordsError, metaTitleError, loginImageError, fevListImageError,  domoreImageError, selecteError
            })
            return true
        }

    }
   
   


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Optionally validate the form data
        let valid = validate();
        if (!valid) {
            return;
        }
    
        try {
            const res = await saveaddDomainAction(form); // Assuming this handles the FormData appropriately
            console.log(form);
    
            if (res.success) {
                toast.success(res.msg);
                window.location.href=`${config.baseUrl}domains`
               
            } else {
                toast.error(res.msg);
            }
        } catch (error) {
            console.error("Error during submission:", error);
            toast.error("An error occurred. Please try again.");
        }
    };
    
    const resetForm = () => {
        setForm({
            domainUrl: '',
            title: '',
            webmaster: '',
            zipRange: '',
            showAds: '',
            domainImage: '',
            onlineImage: '',
            mapImage: '',
            freeAdsImage: '',
            manageAdImage: '',
            loginImage: '',
            domoreImage: '',
            fbLoginImage: '',
            fbLogoutImage: '',
            searchedImage: '',
            fevListImage: '',
            metaTitle: '',
            metaDescription: '',
            metaKeywords: '',
            fbShareTitle: '',
            fbShareDescription: ''
        });

    };
    return (
        <div className="wrapper">
            <Header />
            <Toaster />
            <Sidebar />
            <div className="content-wrapper">
                <div className="container-full">
                    <div className="content-header">
                        <h3 className="page-title mb-5 pb-2">Add new domain</h3>
                        <hr />
                    </div>

                    <section className="content">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="box">
                                    <div className="box-header with-border">
                                        <h4 className="box-title">Add new domain</h4>
                                        <a href={`${config.baseUrl}domains`} className="btn btn-sm btn-primary add_btn">Back</a>
                                    </div>
                                    <div className="row mt-20 mb-50 ml-15 mr-15">
                                        <form onSubmit={(e)=>e.preventDefault()}>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6 ">
                                                        {/* Domain URL */}
                                                        <div className="form-group">
                                                            <label className="col-form-label">
                                                                Domain url <span className="req-star"></span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="domainUrl"
                                                                onChange={inputHandler}
                                                                placeholder="Enter Domain URL"
                                                                value={form.domainUrl}
                                                            />
                                                            <span className="validationErr">{validationError.domainUrlError}</span>
                                                        </div>

                                                        {/* Domain Title */}
                                                        <div className="form-group">
                                                            <label className="col-form-label">
                                                                Domain title <span className="req-star"></span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="title"
                                                                onChange={inputHandler}
                                                                placeholder="Enter Title"
                                                                value={form.title}
                                                            />
                                                            <span className="validationErr">{validationError.titleError}</span>
                                                        </div>

                                                        {/* Domain Webmaster */}
                                                        <div className="form-group">
                                                            <label className="col-form-label">
                                                                Domain web master <span className="req-star"></span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="webmaster"
                                                                onChange={inputHandler}
                                                                placeholder="Enter Webmaster"
                                                                value={form.webmaster}
                                                            />
                                                            <span className="validationErr">{validationError.webmasterError}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        {/* Zip Range */}
                                                        <div className="form-group">
                                                            <label className="col-form-label">
                                                                Zip range
                                                            </label>
                                                            <textarea
                                                                className="form-control"
                                                                name="zipRange"
                                                                onChange={inputHandler}
                                                                placeholder="Enter Zip Range"
                                                                value={form.zipRange}
                                                            />
                                                            <span className="validationErr">{validationError.zipRangeError}</span>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-form-label">
                                                                Show ads by range
                                                            </label>
                                                            <div className='d-flex justify-center grid gap-2 grid-cols-2' >

                                                                <input
                                                                    style={{
                                                                        position: "sticky",
                                                                        opacity: "1"
                                                                    }}
                                                                    type="radio"
                                                                    name="showAds"
                                                                    value="1"
                                                                    onChange={inputHandler}
                                                                /> Yes


                                                                <input
                                                                    style={{
                                                                        position: "sticky",
                                                                        opacity: "1"
                                                                    }}
                                                                    type="radio"
                                                                    name="showAds"
                                                                    value="0"
                                                                    onChange={inputHandler}
                                                                /> no
                                                                <span className="validationErr">
                                                                    {validationError.selecteError}
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Domain Images */}

                                                <div className="row">
                                                    <div className="form-group col-lg-3">
                                                        <label className="col-form-label">
                                                            Domain image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="domainImage"
                                                            onChange={PicChange}
                                                        />
                                                        <span className="validationErr">{validationError.domainImageError}</span>
                                                    </div>
                                                    <div className="form-group col-lg-3">
                                                        <label className="col-form-label">
                                                            Online image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="onlineImage"
                                                            onChange={PicChange}
                                                        />
                                                        <span className="validationErr">{validationError.onlineImageError}</span>
                                                    </div>
                                                    <div className="form-group col-lg-3">
                                                        <label className="col-form-label">
                                                            Left sidebar free ads image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="freeAdsImage"
                                                            onChange={PicChange}
                                                        />
                                                        <span className="validationErr">{validationError.freeAdsImageError}</span>
                                                    </div>
                                                    <div className="form-group col-lg-3">
                                                        <label className="col-form-label">
                                                            Left sidebar manage ad image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="manageAdImage"
                                                            onChange={PicChange}
                                                        />
                                                        <span className="validationErr">{validationError.manageAdImageError}</span>
                                                    </div>
                                                    <div className="form-group col-lg-3">
                                                        <label className="col-form-label">
                                                            Left sidebar login image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="loginImage"
                                                            onChange={PicChange}
                                                        />
                                                        <span className="validationErr">{validationError.loginImageError}</span>
                                                    </div>
                                                    <div className="form-group col-lg-3">
                                                        <label className="col-form-label">
                                                            Left sidebar searched image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="searchedImage"
                                                            onChange={PicChange}
                                                        />
                                                        <span className="validationErr">{validationError.searchedImageError}</span>
                                                    </div>
                                                    <div className="form-group col-lg-3">
                                                        <label className="col-form-label">
                                                            Left sidebar fev-list image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="fevListImage"
                                                            onChange={PicChange}
                                                        />
                                                        <span className="validationErr">{validationError.fevListImageError}</span>
                                                    </div>
                                                    <div className="form-group col-lg-3">
                                                        <label className="col-form-label">
                                                            Map image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="mapImage"
                                                            onChange={PicChange}
                                                        />
                                                        <span className="validationErr">{validationError.mapImageError}</span>
                                                    </div>
                                                    <div className="form-group col-lg-3">
                                                        <label className="col-form-label">
                                                            Domore image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="domoreImage"
                                                            onChange={PicChange}
                                                        />
                                                        <span className="validationErr">{validationError.domoreImageError}</span>
                                                    </div>
                                                    <div className="form-group col-lg-3">
                                                        <label className="col-form-label">
                                                            Fb login image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="fbLoginImage"
                                                            onChange={PicChange}
                                                        />
                                                        <span className="validationErr">{validationError.fbLoginImageError}</span>
                                                    </div>
                                                    <div className="form-group col-lg-3">
                                                        <label className="col-form-label">
                                                            Fb logout image: <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="fbLogoutImage"
                                                            onChange={PicChange}
                                                        />
                                                        <span className="validationErr">{validationError.fbLogoutImageError}</span>
                                                    </div>
                                                </div>


                                                {/*SEO Section */}
                                                <h3>Classified SEO</h3>

                                                <div className="row">
                                                    {/* Meta Title */}
                                                    <div className="form-group col-lg-4">
                                                        <label className="col-form-label">
                                                            Meta title
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            name="metaTitle"
                                                            onChange={inputHandler}
                                                            placeholder="Enter Meta Title"
                                                            value={form.metaTitle}
                                                        />
                                                        <span className="validationErr">{validationError.metaTitleError}</span>
                                                    </div>

                                                    {/* Meta Description */}
                                                    <div className="form-group col-lg-4">
                                                        <label className="col-form-label">
                                                            Meta description
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            name="metaDescription"
                                                            onChange={inputHandler}
                                                            placeholder="Enter Meta Description"
                                                            value={form.metaDescription}
                                                        />
                                                        <span className="validationErr">{validationError.metaDescriptionError}</span>
                                                    </div>

                                                    {/* Meta Keywords */}
                                                    <div className="form-group col-lg-4">
                                                        <label className="col-form-label">
                                                            Meta keywords
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            name="metaKeywords"
                                                            onChange={inputHandler}
                                                            placeholder="Enter Meta Keywords"
                                                            value={form.metaKeywords}
                                                        />
                                                        <span className="validationErr">{validationError.metaKeywordsError}</span>
                                                    </div>



                                                    {/* Facebook Share Title */}
                                                    <div className="form-group col-lg-4">
                                                        <label className="col-form-label">
                                                            Facebook share title <span className="req-star"></span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="fbShareTitle"
                                                            onChange={inputHandler}
                                                            placeholder="Enter Facebook Share Title"
                                                            value={form.fbShareTitle}
                                                        />
                                                        <span className="validationErr">{validationError.fbShareTitleError}</span>
                                                    </div>

                                                    {/* Facebook Share Description */}
                                                    <div className="form-group col-lg-4">
                                                        <label className="col-form-label">
                                                            Facebook share description <span className="req-star"></span>
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            name="fbShareDescription"
                                                            onChange={inputHandler}
                                                            placeholder="Enter Facebook Share Description"
                                                            value={form.fbShareDescription}
                                                        />
                                                        <span className="validationErr">{validationError.fbShareDescriptionError}</span>
                                                    </div>

                                                </div>

                                                {/* Submit and Reset Buttons */}
                                                <div className="form-group d-flex grid gap-2 grid-cols-2">
                                                    <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                                                    <button type="button" className="btn btn-secondary" onClick={resetForm}>Reset</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default AddDomain;
