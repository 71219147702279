import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import ReactDatatable from '@ashvin27/react-datatable';
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import Cookies from 'js-cookie'
import {getContentlistAction, ContentDeleteAction, ContentBlockUnBlockAction } from '../Action/action'
import avt from '../assets/avata_profile.jpg'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));
const Contentpages = () => {
	const [contentList, setContentList]	= useState([]);
	const [domain_title, setdomain_title]	= useState([]);
	
	useEffect(() => {
		if (!loginData?.email) {
			window.location.href = `${config.baseUrl}login`
		}
		
		getContentlist(); 
	}, [])
	
	const getContentlist = async () => {
		let res = await getContentlistAction();
		if (res.success) {
			let data = res.data;
			setContentList(data);
			console.log(contentList)
		}
		else
		{
			setContentList([]);	
		}
	}
	
	const ContentDelete = async (id) => {
		Swal.fire({
			title: 'Are you sure? ',
			text: "You want to Delete this Content Page!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await ContentDeleteAction({'id':id});
				if (res.success) {
					getContentlist();
					Swal.fire(
                        'Deleted!',
                        res.msg,
                        'success'
                    )
				} else {
					Swal.fire(
                        'Failed!',
                        res.msg,
                        'error'
                    )
				}
			}
		});
	}
	
	const columns = [
		{
			key: "Sno.",
			text: "#",
			cell: (row, index) => index + 1,
		},
		{
			key: "page_name",
			text: "Page Name"
		},
	
		{
			key: "createdon",
			text: "Page Date"     
		},
		{
			key:"title",
			text: "Domain"     
		},
		{
			key: "status",
			text: "Action",
			cell: (item) => {
				return (
					<>
						<div className="btn-group mb-5">
							{ item.status === 0 ?
								<> 
									<button type="button" className="btn btn-danger btn-sm " onClick={() => ContentBlockUnBlock(item.id, 1)}><i className='fa fa-unlock'></i> Inactive</button>
									&nbsp;&nbsp;&nbsp; <Link to={`${config.baseUrl}edit-content/` + item.id}>
										<button className='btn btn-sm btn-primary'><i className='fa fa-pencil'></i>  Edit</button>
									</Link> &nbsp; 
									&nbsp;&nbsp; <button type="button" className="btn btn-danger btn-sm " onClick={() => ContentDelete(item.id)}><i className='fa fa-trash'></i> Delete</button>
								</>
								: item.status === 1 ?
								<>
									<button type="button" className="btn btn-primary btn-sm " onClick={() => ContentBlockUnBlock(item.id, 0)}><i className='fa fa-ban'></i> Active</button>
									&nbsp;&nbsp;&nbsp; <Link to={`${config.baseUrl}edit-content/` + item.id}>
										<button className='btn btn-sm btn-primary'><i className='fa fa-pencil'></i>  Edit</button>
									</Link> &nbsp; 
									&nbsp;&nbsp; <button type="button" className="btn btn-danger btn-sm " onClick={() => ContentDelete(item.id)}><i className='fa fa-trash'></i> Delete</button>&nbsp;
								</>
								:
								'' 
							}
						</div>
					</>
				);
			}
		}
	];
	
	const configForTable = {
		page_size: 10,
		length_menu: [10, 20, 50],
		show_filter: true,
		show_pagination: true,
		pagination: "advance",
		button: {
			excel: false,
			print: false,
		},
	};
	
	
	const ContentBlockUnBlock = async (id, status) => {
		Swal.fire({
			title: 'Are you sure?',
			text: status == 0 ? "You want to deactivate this content page!”" : "You want to activate this content page!”",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: status == 0 ? 'Yes, deactivate it!' : 'Yes, activate it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
			let res = await ContentBlockUnBlockAction({ 'id': id, 'status': status });
				if (res.success) {
					getContentlist();
					Swal.fire(
						status == 0 ? 'Deactivate!' : 'Activate',
						res.msg,
						'success'
					)
				} else {
					Swal.fire(
						'Failed!',
						res.msg,
						'error'
					)
				}
			}
		})
	}
	
	
	return (
		<>
			<div className="wrapper">
				<Header />
				<Toaster />
				<Sidebar />
				
				<div className="content-wrapper">
					<div className="container-full">
						{/* Main content */}
						<div className="content-header">
							<div className="d-flex align-items-center">
								<div className="me-auto">
									<h3 className="page-title mb-5 pb-2">Content Pages</h3>
								</div>
							</div>
							<hr />
						</div>
						<section className="content">
							<div className="row">
								<div className="col-lg-12 col-12">
									<div className="box">
										<div className="box-header with-border">
											<div className="row mb-15">
											<h4 className="box-title col-md-3">Content Pages ({contentList.length})</h4>
											<div className="col-md-9 text-right">
											<a href={`${config.baseUrl}add-content`} className="btn btn-sm btn-primary add_btn">
												<i className='fa fa-plus'></i> Add Content Page</a>
											</div>
										</div>
										
										</div>
										<div className="box-body">
										<ReactDatatable
												config={configForTable}
												records={contentList}				
												columns={columns}
											/>
										</div>
									</div>
								</div>
							</div>
						</section>                       
					</div>
				</div>      	
				
				<Footer />
				
			</div>
		</>
	);
}

export default Contentpages;