import React, { useEffect, useState } from 'react';
import Header from '../directives/header';
import Sidebar from '../directives/sidebar';
import Footer from '../directives/footer';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import config from '../coreFIles/config';
import { getdomainListAction, deteteDomainAction } from '../Action/action';

const Domain = () => {
    const [domainList, setDomainList] = useState([]);

    console.log(domainList)

    useEffect(() => {
        getdomainsListApi();
    }, []);

    const getdomainsListApi = async () => {
        try {
            const res = await getdomainListAction();
            if (res.success) {
                setDomainList(res.data);

            } else {
                setDomainList([]);
            }
        } catch (error) {
            toast.error('Failed to fetch domain list.');
        }
    };

    const detete = async (detail) => {
        Swal.fire({
            title: 'Are you sure? ',
            text: "You want to Delete this Domain!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await deteteDomainAction({ id: detail.id })
                if (res.success) {
                    toast.success(res.msg);
                    getdomainsListApi();
                } else {
                    toast.error(res.msg);
                }
            }
        });
    }


    return (

        <div className="wrapper">
            <Toaster />
            <Header />
            <Sidebar />
            <div className="content-wrapper">
                <div className="container-full">
                    <div className="content-header">
                        <div className="d-flex align-items-center">
                            <div className="me-auto">
                                <h3 className="page-title mb-5 pb-2">Region List</h3>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <section className="content">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="box">
                                    <div className="box-header with-border">
                                        <div className="row mb-15">
                                            <h4 className="box-title col-md-2">List ({domainList.length})</h4>
                                            <div className="col-md-10 text-right">
                                                <Link to={`${config.baseUrl}add-domain`}>
                                                    <button className="btn btn-sm btn-primary add_btn">
                                                        <i className='fa fa-plus'></i> Add New Domain
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-body table-container ">

                                        <table className='table table-bordered table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Url</th>
                                                    <th>Title</th>
                                                    <th style={{ width: "40%" }}>Zip range</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {domainList.map((detail, index) => (
                                                    <tr key={detail.id} >
                                                        <td>{index + 1}</td>
                                                        <td>{detail.url}</td>
                                                        <td>{detail.title}</td>
                                                        <td style={{
                                                            maxWidth: "200px",
                                                            maxHeight: "fit-content",
                                                            whiteSpace: "unset",
                                                            wordWrap: "break-word",
                                                            verticalAlign: "top"
                                                        }}>
                                                            {detail.zip_range}
                                                        </td>
                                                        <td>
                                                            <Link to={`${config.baseUrl}edit-domain/${detail.id}`}>
                                                                <button className='btn btn-sm btn-primary'>
                                                                    <i className='fa fa-pencil'></i> Edit
                                                                </button>
                                                                &nbsp;&nbsp;&nbsp;
                                                            </Link>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => detete(detail)}>
                                                                <i className='fa fa-trash'></i> Delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
            <Footer />
        </div >


    );
};

export default Domain;
