import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import JoditEditor from "jodit-react";
import config from "../coreFIles/config";
import Cookies from 'js-cookie'
import { AddDomaincatdiscriptionAction,getMasterContentAction } from "../Action/action";
import { useParams } from "react-router-dom";
const loginData = (!Cookies.get('loginSuccessBondiAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessBondiAdmin'));


const AddDomaincatdiscription = () => {
    const {id} = useParams()
    const editorHeight = '400px';
    const [domainList, setDomainList] = useState('');
    const [spinloader, setspinloader] = useState(0);
    const [validatioError, setvalidatioError] = useState({});
    const [catdata, setcatdata] = useState({
        meta_title: '',
        meta_desc: '',
        meta_keywords: '',
        cat_id:id
    });


    useEffect(() => {
        if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }
        getDomainlist()
    }, [])
    // get domain list
    const getDomainlist = async () => {
		let res = await getMasterContentAction();
		if (res.success) {
			let data = res.data;
			setDomainList(data.domainlists)
		
		}
		else
		{
			setDomainList([]);	
		}
	}
   
    
    const inputHandler = async (e) => {
        const { name, value } = e.target;
        setcatdata((old) => {
            return { ...old, [name]: value };
        });
    };
    const meta_descHandler = async (e) => {
        setcatdata((old) => {
            return { ...old, 'meta_desc': e }
        })
    }

    const meta_keywordsHandler = async (e) => {
        setcatdata((old) => {
            return { ...old, 'meta_keywords': e }
        })
    }

    function validate() {
        let cat_nameError = "";
        let cat_flagError = "";

        if (catdata.cat_name === "") {
            cat_nameError = "Name is required.";
        }
        if (catdata.cat_flag === "") {
            cat_flagError = "Status is required.";
        }
        if (cat_nameError || cat_flagError) {
            setvalidatioError({ cat_nameError, cat_flagError });
            return false;
        } else {
            setvalidatioError({ cat_nameError, cat_flagError });
            return true;
        }
    }
    const handleKeyPress = (e) => {
        // Allow only numeric values (0-9) and certain control keys
        const isValidInput = /^[0-9\b]+$/.test(e.key);

        if (!isValidInput) {
            e.preventDefault();
        }
    };

    const AddDomaincatdiscriptionApi = async (e) => {
        e.preventDefault()
        console.log(catdata)
        const isValid = validate();
        console.log(isValid);
        if (!isValid) {
        }
        else {
            setspinloader(1);
            let res = await AddDomaincatdiscriptionAction(catdata);
            if (res.success) {
                toast.success(res.msg);
                setTimeout(() => {
                    window.location.href = `${config.baseUrl}domain-discription/` +id;
                }, 2000);
            }
            else {
                toast.error(res.msg);
            }
        }
    }

    return (
        <>
            <div className="wrapper">
                <Header />
                <Toaster />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Add discription</h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                        {/* Content Header (Page header) */}

                        {/* Main content */}
                        <section className="content">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="box">
                                        <div className="box-header with-border">
                                            <h4 className="box-title">Add discription</h4>
                                            <a href={`${config.baseUrl}categories`} className="btn btn-sm btn-primary add_btn">Back</a>
                                        </div>
                                        <div className="row mt-20 mb-50 ml-15 mr-15">
                                            <form onSubmit={AddDomaincatdiscriptionApi}>
                                                <div className="col-md-12">
                                                    <div className="row">                                                        
                                                        <div className="col-md-3">
                                                        <div className="form-group row mb-1">
																<label className="col-form-label col-md-12">
																	Domain <span className="req-star">*</span>
																</label>
																<div className="col-md-12">
																	<select name='domain_id' className="form-control" id='domain_idError' onChange={inputHandler}>
																		<option value="">Select</option>
																		{domainList.length > 0 ?
																			domainList.map(domain => (
																				<option value={domain.id}>{domain.title}</option>
																			)) : ''
																		}
																	</select>
																	<span className="validationErr">
																		{validatioError.domain_idError}
																	</span>
																</div>
															</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group row mb-1">
                                                                <label className="col-form-label col-md-12">Meta title </label>
                                                                <div className="col-md-12">
                                                                    <input className="form-control" type="text" name="meta_title" id='meta_titleError' onChange={inputHandler} placeholder="Enter meta title" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group row mb-1">
                                                                <label className="col-form-label col-md-12">Meta Description</label>
                                                                <div className="col-md-12">
                                                                    <JoditEditor
                                                                        onChange={meta_descHandler}
                                                                        value=''
                                                                        name="meta_desc"
                                                                        id="meta_desc"
                                                                        style={{ height: editorHeight }}
                                                                        className="form-control"
                                                                        placeholder="Enter meta desc"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group row mb-1">
                                                                <label className="col-form-label col-md-12">Meta keywords</label>
                                                                <div className="col-md-12">
                                                                    <JoditEditor
                                                                        onChange={meta_keywordsHandler}
                                                                        value=''
                                                                        name="meta_keywords"
                                                                        id="meta_keywords"
                                                                        style={{ height: editorHeight }}
                                                                        className="form-control"
                                                                        placeholder="Enter meta keywords"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <br />
                                                            <div className="text-center pull-left">
                                                                {spinloader == '0' ?
                                                                    <button className='btn btn-primary' >Submit</button>
                                                                    :
                                                                    <button disabled className='btn btn-primary' >Submiting <i className="fa fa-spinner fa-spin validat"></i></button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* /.content */}
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
};
export default AddDomaincatdiscription;
